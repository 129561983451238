import Helmet from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"

function SEO({ description, meta, keywords, title }) {
  const intl = useIntl()
  const baseAuthor = intl.formatMessage({ id: "siteMetadata.author" })
  const baseDescription = intl.formatMessage({ id: "siteMetadata.description" })
  const baseTitle = intl.formatMessage({ id: "siteMetadata.title" })

  const baseKeywords = Object.keys(intl.messages)
    .filter((key) => key.includes("siteMetadata.keywords"))
    .reduce((arr, key) => {
      arr.push(intl.messages[key])
      return arr
    }, [])
  const metaKeywords = keywords.length > 0 ? keywords.concat(baseKeywords) : baseKeywords
  const metaDescription = description || baseDescription
  const metaTitle = title ? `${title} - ${baseTitle}` : baseTitle

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        { name: `description`, content: metaDescription },
        { name: `keywords`, content: metaKeywords.join(`, `) },
        { property: `og:title`, content: metaTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:creator`, content: baseAuthor },
        { name: `twitter:title`, content: metaTitle },
        { name: `twitter:description`, content: metaDescription }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
}

export default SEO
