import { changeLocale, FormattedMessage, injectIntl, useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import ReactCountryFlag from "react-country-flag"
import { Col, Row } from "react-simple-flex-grid"
import "./layout.scss"
import Link from "./link"

const Nav = () => {
  const intl = useIntl()

  return (
    <nav className="NavBar">
      <Row align="center" justify="space-between" className="NavBar__Wrapper">
        <Link to="/" className="Text__White NavBar__Logo NavBar__Link">
          JB
        </Link>
        <Row align="center" className="NavBar__Links">
          <Col>
            <Link
              activeClassName="NavBar__Link__Active"
              to="/projects/"
              className="Text__Darken NavBar__Link Text__Underline Text__H4"
            >
              <FormattedMessage id="projects.nav" />
            </Link>
          </Col>
          <Col>
            <Link
              activeClassName="NavBar__Link__Active"
              to="/about/"
              className="Text__Darken NavBar__Link Text__Underline Text__H4"
            >
              <FormattedMessage id="about.nav" />
            </Link>
          </Col>

          <Col>
            <ReactCountryFlag
              alt={intl.locale === "en" ? "drapeau français" : "english flag"}
              svg
              className="Flag"
              style={{
                width: "1.25em",
                height: "1.25em",
                marginLeft: "32px",
                marginRight: "16px",
                cursor: "pointer"
              }}
              countryCode={intl.locale === "en" ? "fr" : "US"}
              onClick={() => changeLocale(intl.locale === "en" ? "fr" : "en")}
            />
          </Col>
        </Row>
      </Row>
    </nav>
  )
}

const Layout = ({ children }) => {
  return (
    <>
      <div className="App">
        <Nav />
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default injectIntl(Layout)
