import AniLink from "gatsby-plugin-transition-link/AniLink"
import FadeLink from "./FadeLink"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"

function Link({ children, paint, to, ...props }) {
  const intl = useIntl()
  const locale = intl.locale
  const rewrite = locale !== intl.defaultLocale
  const url = rewrite ? "/" + locale + to : to
  // const directions = ["left", "right", "up", "down"]
  // const randomDirection = directions[Math.floor(Math.random() * directions.length)]
  const colors = [
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f39c12",
    "#e67e22",
    "#e74c3c",
    "#ecf0f1",
    "#95a5a6"
  ]
  const randomColor = colors[Math.floor(Math.random() * colors.length)]

  if (paint) {
    return (
      <AniLink paintDrip hex={randomColor} duration={1} to={url} {...props}>
        {children}
      </AniLink>
    )
  }
  return (
    <FadeLink duration={1} to={url} {...props}>
      {children}
    </FadeLink>
  )
}

export default Link
